<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center vh-100"
  >
    <img
      :src="require('@/assets/images/logo/logo-golog_720.png')"
      style="height: 70px; width: 300px"
      class="my-1"
    />

    <div class="card" style="width: 100vw; height: 90vh; overflow: auto">
      <div class="card-body">
        <h5 class="card-title text-center">Investor Details</h5>
        <div class="row">
          <div class="col-md-6">
            <!-- First column content -->
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="investorName"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Investor Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Investors Name"
                  id="investorName"
                  v-model="investorName"
                  @keyup.enter="addInvestorName"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="gender"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Gender</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Gender"
                  id="gender"
                  v-model="gender"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="address"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Address</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Address"
                  id="address"
                  v-model="address"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="email"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Email</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Email"
                  id="email"
                  v-model="email"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="contactNumber"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Contact Number</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Contact Number"
                  id="contactNumber"
                  v-model="contactNumber"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="country"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Country</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Country"
                  id="country"
                  v-model="country"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="nationality"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Nationality</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Nationality"
                  id="nationality"
                  v-model="nationality"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="position"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Position/Job Title</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Position/Job Title"
                  id="position"
                  v-model="position"
                  style="width: 700px"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="organizationDetail"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Organization Detail</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Organization Detail"
                  id="organizationDetail"
                  v-model="organizationDetail"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="organizationName"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Organization Name</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Organization Name"
                  id="organizationName"
                  v-model="organizationName"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="organizationAddress"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Organization Address</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Organization Address"
                  id="organizationAddress"
                  v-model="organizationAddress"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="organizationContactNumber"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Organization Contact Number</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Organization Contact Number"
                  id="organizationContactNumber"
                  v-model="organizationContactNumber"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="organizationEmail"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Organization Email</label
                >
                <input
                  type="text"
                  class="form-control"
                  placeholder="Add Organization Email"
                  id="organizationEmail"
                  v-model="organizationEmail"
                  style="width: 700px"
                />
              </div>
            </div>
            <div class="mb-3 w-75 mx-auto">
              <div class="d-flex align-items-center">
                <label
                  for="nameCardPicture"
                  class="form-label mr-1"
                  style="font-size: 15px"
                  >Name Card Picture</label
                >
                <input
                  type="file"
                  class="form-control"
                  id="nameCardPicture"
                  <input
                  type="file"
                  class="form-control"
                  id="nameCardPicture"
                  @change="handleFileUpload"
                  style="width: 700px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mb-3 w-75 mx-auto">
        <ul class="list-group">
          <li
            v-for="(name, index) in investorNames"
            :key="index"
            class="list-group-item d-flex justify-content-between align-items-center"
          >
            {{ name }}
            <button
              class="btn btn-danger btn-sm"
              @click="removeInvestorName(index)"
            >
              Delete
            </button>
          </li>
        </ul>
      </div>
      <div class="text-center">
        <!-- <button class="btn btn-primary mt-3" @click="addInvestorName">
            Add Investor
          </button> -->
      </div>
    </div>
    <button class="btn btn-success" @click="submitInvestorDetails">
      Confirm
    </button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      investorName: "",
      investorNames: [],
    };
  },
  methods: {
    addInvestorName() {
      if (this.investorName) {
        this.investorNames.push(this.investorName);
        this.investorName = "";
      }
    },
    removeInvestorName(index) {
      this.investorNames.splice(index, 1);
    },
    submitInvestorDetails() {
      this.$router.push("/moa-agreement");
    },
  },
};
</script>
